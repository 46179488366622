@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    min-height: 100% !important;
    height: 100%;
}

#root {
    min-height: 100% !important;
    height: 100%;
    box-sizing: border-box;
    padding: 1.5rem;
    background-color: theme('colors.sky.900');
}
